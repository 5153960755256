<template>
  <!--微信菜单栏: 校企合作 财商导师-->
  <div class="root">
    <v-tabs centered grow v-model="tab" class="tabs" @change="changeTab">
      <v-tab>校企合作</v-tab>
      <v-tab>财商导师</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="pt-10" @change="changeTab">
      <v-tab-item>
        <!--新闻列表-->
        <m-news-list :news-list="xqhz"></m-news-list>
      </v-tab-item>
      <v-tab-item>
        <m-news-list :news-list="csds"></m-news-list>
      </v-tab-item>
    </v-tabs-items>

    <!--加载中提示-->
    <v-progress-circular
      indeterminate
      color="primary"
      class="loading"
      v-if="loading"
    ></v-progress-circular>
  </div>
</template>

<script>
  import TimeFormatter from "@/utils/timeFormatter"
  import MNewsList from "@/components/common/MNewsList"

  export default {
    name: "MCategory2",
    components: {
      MNewsList
    },
    metaInfo: {
      title: '校企合作|财商导师',
    },
    data() {
      return {
        tab: null,
        xqhz: [],
        xqhzPage: 1,
        xqhzTotalPage: 3,
        csds: [],
        csdsPage: 1,
        csdsTotalPage: 3,
        loading: false,
        limit: 10 // 每次加载多少页
      }
    },
    methods: {
      // 切换tab
      changeTab(e) {
        console.log(e)
        // 第一次切到财商导师时, 加载数据
        if(e === 1 && !this.csds.length) {
          this.initCsdsData()
        }
      },
      // 初始获取财商导师新闻
      initCsdsData() {
        this.$axios.news.getNewsFast({
          sort: 'relTime',
          types: 'csds',
          page: 0,
          limit: this.limit
        }).then(res => {
          this.csdsTotalPage = Math.ceil(res.data.total / this.limit)
          this.csds = this.csds.concat(res.data.list.map(item => {
            item.relTime = new TimeFormatter(item.relTime).getMacroTime()
            return item
          }))
        })
      },
      // 获取更多校企合作
      getMoreXqhz() {
        this.loading = true
        this.$axios.news.getNewsFast({
          sort: 'relTime',
          types: 'xqhz',
          page: this.xqhzPage,
          limit: this.limit
        }).then(res => {
          this.xqhz = this.xqhz.concat(res.data.list.map(item => {
            item.relTime = new TimeFormatter(item.relTime).getMacroTime()
            return item
          }))
          this.$nextTick(() => {
            this.loading = false
            let scrollY = window.scrollY
            let pageHeight = window.innerHeight
            window.scrollTo({
              top: scrollY + pageHeight,
              behavior: "smooth"
            });
          })
        })
      },
      // 获取更多财商导师
      getMoreCsds() {
        this.loading = true
        this.$axios.news.getNewsFast({
          sort: 'relTime',
          types: 'csds',
          page: this.csdsPage,
          limit: this.limit
        }).then(res => {
          this.csds = this.csds.concat(res.data.list.map(item => {
            item.relTime = new TimeFormatter(item.relTime).getMacroTime()
            return item
          }))
          this.$nextTick(() => {
            this.loading = false
            let scrollY = window.scrollY
            let pageHeight = window.innerHeight
            window.scrollTo({
              top: scrollY + pageHeight,
              behavior: "smooth"
            });
          })
        })
      },
    },
    created() {
      this.$axios.news.getNewsFast({
        sort: 'relTime',
        types: 'xqhz',
        page: 0,
        limit: this.limit
      }).then(res => {
        this.xqhzTotalPage = Math.ceil(res.data.total / this.limit)
        this.xqhz = this.xqhz.concat(res.data.list.map(item => {
          item.relTime = new TimeFormatter(item.relTime).getMacroTime()
          return item
        }))
        // 拿到数据后, 开始监听触底
        this.$nextTick(() => {
          document.addEventListener('scroll', () => {
            // 当前滚动距离
            let scrollY = window.scrollY
            // 一页高度
            let pageHeight = window.innerHeight
            // 页面总高度
            let totalHeight = document.body.scrollHeight
            // 触底
            if(scrollY + pageHeight === totalHeight) {
              // 判断加载哪一种新闻
              if(this.tab === 0 && this.xqhzPage < this.xqhzTotalPage) {
                this.getMoreXqhz()
                this.xqhzPage++
                console.log('校企合作')
              }
              else if(this.tab === 1 && this.csdsPage < this.csdsTotalPage) {
                this.getMoreCsds()
                this.csdsPage++
                console.log('财商导师')
              }
            }
          })
        })
      })
    }
  }
</script>

<style lang="scss" scoped>
  .tabs {
    position: fixed;
    z-index: 10;
  }
  // 加载中提示
  .loading {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
</style>
